// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** Accidents
export const getAccidentsList = createAsyncThunk('appAccidents/getAccidentsList', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getAccidentsList', params))
   return {
      params,
      data: response.data.roadAccidents,
      totalPages: response.data.total_elements
   }
})
// ** Add new accident
export const addAccident = createAsyncThunk('appAccidents/addAccident', async (data) => {
   const response = await axios.post(apiLink('addAccident'), data)
   return response.data
})
// ** Get accident by id
export const getAccident = createAsyncThunk('appAccidents/getAccident', async id => {
   const response = await axios.get(apiLink({name: 'getAccident', value: id}))
   return response.data
})
// ** Update accident by id
export const updateAccident = createAsyncThunk('appAccidents/updateAccident', async data => {
   const accident_id = data.id
   delete data.id
   const response = await axios.put(apiLink({name: 'updateAccident', value: accident_id}), data)
   return response.data
})
// ** Complete accident by id
export const completeAccident = createAsyncThunk('appAccidents/completeAccident', async id => {
   const response = await axios.put(apiLink({name: 'completeAccident', value: id}))
   return response.data
})
// ** Restore accident from archive
export const restoreAccident = createAsyncThunk('appAccidents/restoreAccident', async id => {
   const response = await axios.put(apiLink({name: 'restoreAccident', value: id}))
   return response.data
})
// ** Remove accident appeal
export const removeAccidentAppeal = createAsyncThunk('appAccidents/removeAccidentAppeal', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeAccidentAppeal', value: id}))
   return response.data
})

// ** Cars list
export const getAccidentCars = createAsyncThunk('appAccidents/getAccidentCars', async () => {
   const params = {sort_list: 'id', sort_order: 'ASC', rows: 1000}
   const response = await axios.get(apiLink('getCarsShortList', params))
   return response.data.cars
})
// ** Customers list
export const getAccidentCustomers = createAsyncThunk('appAccidents/getAccidentCustomers', async () => {
   const params = {active: true, sort_list: 'first_name', sort_order: 'ASC', rows: 1000, blacklist: false}
   const response = await axios.get(apiLink('getCustomersShortList', params))
   return response.data.customers
})

// ** Appeals Add/Update
export const addAccidentInsuranceAppeal = createAsyncThunk('appAccidents/addAccidentInsuranceAppeal', async data => {
   const accident_id = data.id
   delete data.id
   const response = await axios.post(apiLink({name: 'addAccidentInsuranceAppeal', value: accident_id}), data)
   return response.data
})
export const updateAccidentInsuranceAppeal = createAsyncThunk('appAccidents/updateAccidentInsuranceAppeal', async data => {
   const appeal_id = data.id
   delete data.id
   const response = await axios.put(apiLink({name: 'updateAccidentInsuranceAppeal', value: appeal_id}), data)
   return response.data
})
export const addAccidentInsuranceAppealExpectedPayment = createAsyncThunk('appAccidents/addAccidentInsuranceAppealExpectedPayment', async data => {
   const appeal_id = data.id
   delete data.id
   const response = await axios.post(apiLink({name: 'addAccidentInsuranceAppealExpectedPayment', value: appeal_id}), data)
   return response.data
})
export const removeAppealExpectedPayment = createAsyncThunk('appAccidents/removeAppealExpectedPayment', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeAccidentInsuranceAppealEexpectedPayment', value: id}))
   return response.data
})

// ** Update appeal expert visited
export const updateAccidentInsuranceAppealExpertExamined = createAsyncThunk('appAccidents/updateAccidentInsuranceAppealExpertExamined', async appeal_id => {
   const response = await axios.post(apiLink({name: 'updateAccidentInsuranceAppealExpertExamined', value: appeal_id}))
   return response.data
})


// ** Get insurances by car
export const getCarInsurancesById = createAsyncThunk('appAccidents/getCarInsurancesById', async (id) => {
   const params = {sort_list: 'id', sort_order: 'ASC', rows: 1000, car_id: id}
   const response = await axios.get(apiLink('getInsurancesList', params))
   return response.data.response
})

// ** Payments
export const addPayment = createAsyncThunk('appAccidents/addPayment', async (data) => {
   return await axios.post(apiLink('addPayment'), data)
})
export const removeAppealPayment = createAsyncThunk('appAccidents/removeAppealPayment', async (id) => {
   const response = await axios.delete(apiLink({name: 'removePayment', value: id}, {type: 'CAR_INSURANCE_APPEAL_EXPECTED_PAYMENT'}))
   return response.data
})
export const removeCustomerDebtPayment = createAsyncThunk('appAccidents/removeCustomerDebtPayment', async (id) => {
   const response = await axios.delete(apiLink({name: 'removePayment', value: id}, {type: 'ROAD_ACCIDENT'}))
   return response.data
})

// Media files
export const getMedia = createAsyncThunk('appAccidents/getMedia', async ({accident_id, types}) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: 'ROAD_ACCIDENT'}), {
      ids: [accident_id],
      types
   })
   return response.data.files
})
export const addMedia = createAsyncThunk('appAccidents/addMedia', async ({accident_id, type, typeOwner, file}) => {
   const response = await axios({
        method: "post",
        url: apiLink({name: 'uploadFile', value: accident_id}, {type, typeOwner}),
        data: { file },
        headers: { "Content-Type": "multipart/form-data" }
      })
   return response.data
})
export const removeMedia = createAsyncThunk('appAccidents/removeMedia', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeFile', value: id}, {typeOwner: 'ROAD_ACCIDENT'}))
   return {id, status: response.status}
})

export const appAccidentsSlice = createSlice({
  name: 'appAccidents',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedAccident: null,
    customers: [],
    cars: [],
    media: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAccidentsList.fulfilled, (state, action) => {
         state.data = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
      .addCase(getAccident.fulfilled, (state, action) => {
         state.selectedAccident = action.payload
      })
      .addCase(getAccidentCars.fulfilled, (state, action) => {
         state.customers = action.payload
      })
      .addCase(getAccidentCustomers.fulfilled, (state, action) => {
         state.cars = action.payload
      })
      .addCase(getMedia.fulfilled, (state, action) => {
         state.media = action.payload
      })
  }
})

export default appAccidentsSlice.reducer
