// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

export const getCustomersBlackListed = createAsyncThunk('appCustomersBlackList/getCustomersBlackListed', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getCustomersShortList', params))
   return {
      params,
      data: response.data.customers,
      totalPages: response.data.total_elements
   }
})

export const appCustomersBlackListSlice = createSlice({
   name: 'appCustomersBlackList',
   initialState: {
      data: [],
      total: 1,
      params: {}
   },
   reducers: {},
   extraReducers: builder => {
      builder
      .addCase(getCustomersBlackListed.fulfilled, (state, action) => {
         state.data = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
   }
})

export default appCustomersBlackListSlice.reducer
