// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Import server Config
import apiLink from '@configs/serverConfig'

export const getDepositsList = createAsyncThunk('appDeposits/getDepositsList', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getDepositsList', params))

   return {
      params,
      data: response.data.deposits,
      totalPages: response.data.total_elements
   }
})

export const appDepositsSlice = createSlice({
   name: 'appDeposits',
   initialState: {
      data: [],
      total: 1,
      params: {}
   },
   reducers: {},
   extraReducers: builder => {
      builder
      .addCase(getDepositsList.fulfilled, (state, action) => {
         state.data = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
   }
})

export default appDepositsSlice.reducer
