// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** Profile file actions
export const getUserAvatar = createAsyncThunk('appFiles/getUserAvatar', async (id) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: 'USER'}), {ids: [id], types: ['AVATAR']})
   return response.data.files
})
export const addUserAvatar = createAsyncThunk('appFiles/addUserAvatar', async ({id, type, typeOwner, file}) => {
   const response = await axios({
     method: "post",
     url: apiLink({name: 'profileUploadAvatar', value: id}, {type, typeOwner}),
     data: { file },
     headers: { "Content-Type": "multipart/form-data" }
   })
   return response.data
})
export const removeUserAvatar = createAsyncThunk('appFiles/removeUserAvatar', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeFile', value: id}, {typeOwner: 'USER'}))
   return {id, status: response.status}
})

// ** Customer file actions
export const getCustomerFilesByParams = createAsyncThunk('appFiles/getCustomerFilesByParams', async ({ids, types, owner}) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: owner}), {
      ids,
      types
   })
   return response.data.files
})
export const addCustomersFilePlaceholder = createAsyncThunk('appFiles/addCustomersFilePlaceholder', (file) => {
   return file
})

// ** Car file actions
export const getCarFilesByParams = createAsyncThunk('appFiles/getCarFilesByParams', async ({ids, types, owner}) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: owner}), {
      ids,
      types
   })
   return response.data.files
})
export const addCarsFilePlaceholder = createAsyncThunk('appFiles/addCarsFilePlaceholder', (file) => {
   return file
})

// ** Insurance file actions
export const getInsuranceFilesByParams = createAsyncThunk('appFiles/getInsuranceFilesByParams', async ({ids, types, owner}) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: owner}), {
      ids,
      types
   })
   return response.data.files
})
export const uploadInsuranceFile = createAsyncThunk('appFiles/uploadInsuranceFile', async ({insurance_id, type, typeOwner, file}) => {
   const response = await axios({
        method: "post",
        url: apiLink({name: 'customerUploadFile', value: insurance_id}, {type, typeOwner}),
        data: { file },
        headers: { "Content-Type": "multipart/form-data" }
      })
   return response.data.file
})
export const removeInsuranceFile = createAsyncThunk('appFiles/removeInsuranceFile', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeFile', value: id}, {typeOwner: 'CAR_INSURANCE'}))
   return {id, status: response.status}
})

// ** Payment files
export const getPaymentFilesByParams = createAsyncThunk('appFiles/getPaymentFilesByParams', async ({ids, types, owner}) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: owner}), {
      ids,
      types
   })
   return response.data.files
})
export const uploadPaymentFile = createAsyncThunk('appFiles/uploadPaymentFile', async ({payment_id, type, typeOwner, file}) => {
   const response = await axios({
        method: "post",
        url: apiLink({name: 'customerUploadFile', value: payment_id}, {type, typeOwner}),
        data: { file },
        headers: { "Content-Type": "multipart/form-data" }
      })
   return response.data.file
})
export const removePaymentFile = createAsyncThunk('appFiles/removePaymentFile', async ({id, typeOwner}) => {
   const response = await axios.delete(apiLink({name: 'removeFile', value: id}, {typeOwner}))
   return {id, status: response.status}
})

// **
export const removeFilePlaceholder = createAsyncThunk('appFiles/removeFilePlaceholder', ({owner, var_name, value}) => {
   return {owner, var_name, value}
})
export const addStorageFile =  createAsyncThunk('appFiles/addStorageFile', ({file, module}) => {
   return {file, module}
})
export const removeStorageFile =  createAsyncThunk('appFiles/removeStorageFile', ({id, module}) => {
   return {id, module}
})

export const appFilesSlice = createSlice({
   name: 'appFiles',
   initialState: {
      data: {
         cars: [],
         customers: [],
         insurances: [],
         payments: []
      }
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(addUserAvatar.fulfilled, (state, action) => {
            if (action.payload.file !== undefined) {
               const userData = JSON.parse(localStorage.getItem('userData'))
               localStorage.setItem('userData', JSON.stringify({...userData, avatar: action.payload.file}))
               window.dispatchEvent(new Event("storage"))
            }
         })
         .addCase(getUserAvatar.fulfilled, (state, action) => {
            if (action.payload[0] !== undefined) {
               const userData = JSON.parse(localStorage.getItem('userData'))
               localStorage.setItem('userData', JSON.stringify({...userData, avatar: action.payload[0]}))
            }
         })
         .addCase(removeUserAvatar.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
               const userData = JSON.parse(localStorage.getItem('userData'))
               localStorage.setItem('userData', JSON.stringify({...userData, avatar: undefined}))
               window.dispatchEvent(new Event("storage"))
            }
         })
         .addCase(getCustomerFilesByParams.fulfilled, (state, action) => {
            action.payload.map(item => {
               const res = state.data.customers.find(elm => elm.customer_id === item.customer_id)
               if (res === undefined) {
                  state.data.customers = [...state.data.customers, {...item}]
               }
            })
         })
         .addCase(getCarFilesByParams.fulfilled, (state, action) => {
            action.payload.map(item => {
               const res = state.data.cars.find(elm => elm.car_id === item.car_id)
               if (res === undefined) {
                  state.data.cars = [...state.data.cars, {...item}]
               }
            })
         })
         .addCase(removeFilePlaceholder.fulfilled, (state, action) => {
            state.data[action.payload.owner] = state.data[action.payload.owner].filter(item => (item[action.payload.var_name] !== action.payload.value && item.content === ''))
         })
         .addCase(getInsuranceFilesByParams.fulfilled, (state, action) => {
            state.data.insurances = [...state.data.insurances, ...action.payload].reduce((accumulator, current) => {
               if (!accumulator.find((item) => item.file_id === current.file_id)) {
                  accumulator.push(current)
               }
               return accumulator
            }, [])
         })      
         .addCase(uploadInsuranceFile.fulfilled, (state, action) => {
            state.data.insurances = [...state.data.insurances, action.payload]
         })
         .addCase(removeInsuranceFile.fulfilled, (state, action) => {
            state.data.insurances = state.data.insurances.filter(item => item.file_id !== action.payload.id)
         })
         .addCase(getPaymentFilesByParams.fulfilled, (state, action) => {
            state.data.payments = [...state.data.payments, ...action.payload].reduce((accumulator, current) => {
               if (!accumulator.find((item) => item.file_id === current.file_id)) {
                  accumulator.push(current)
               }
               return accumulator
            }, [])
         })   
         .addCase(uploadPaymentFile.fulfilled, (state, action) => {
            state.data.payments = [...state.data.payments, action.payload]
         })
         .addCase(removePaymentFile.fulfilled, (state, action) => {
            state.data.payments = state.data.payments.filter(item => item.file_id !== action.payload.id)
         })
         .addCase(addCustomersFilePlaceholder.fulfilled, (state, action) => {
            state.data.customers = [...state.data.customers, {...action.payload}]
         })
         .addCase(addCarsFilePlaceholder.fulfilled, (state, action) => {
            state.data.cars = [...state.data.cars, {...action.payload}]
         })
         .addCase(addStorageFile.fulfilled, (state, action) => {
            if (action.payload.file.customer_id !== undefined) {
               state.data[action.payload.module] = state.data[action.payload.module].filter(item => item.customer_id !== action.payload.file.customer_id)
            }
            if (action.payload.file.car_id !== undefined) {
               state.data[action.payload.module] = state.data[action.payload.module].filter(item => item.car_id !== action.payload.file.car_id && item.ttl === false)
            }
            state.data[action.payload.module] = [...state.data[action.payload.module], {...action.payload.file}]
         })
         .addCase(removeStorageFile.fulfilled, (state, action) => {
            state.data[action.payload.module] = state.data[action.payload.module].filter(item => item.file_id !== action.payload.id)
         })
   }
})

export default appFilesSlice.reducer
