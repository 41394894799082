// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import { apiLink, checkTtlExpired } from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** Credit
export const getInsurancesList = createAsyncThunk('appInsurances/getInsurancesList', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getInsurancesList', params))
   return {
      params,
      data: response.data.response,
      totalPages: response.data.total_elements
   }
})

export const getCarInsurances = createAsyncThunk('appInsurances/getCarInsurances', async (params, { getState }) => {
   const state = getState()
   
   const active = params.active !== undefined ? params.active : true
   const existing = state.insurance.all.filter(insurance => insurance.car.id === params.car_id && insurance.active === active && insurance.type === params.type)
   const clear = existing.length > 0 && existing[0] !== undefined ? checkTtlExpired(existing[0].ttl) : false   

   if (existing.length === 0 || clear) {
      const response = await axios.get(apiLink('getInsurancesList', params))      
      return {response: response.data.response, car_id: params.car_id, active, clear}
   }      

   return {response: existing, car_id: params.car_id, active, clear}
})

export const getInsurance = createAsyncThunk('appInsurances/getInsurance', async id => {
   const response = await axios.get(apiLink({name: 'getInsurance', value: id}))
   return {response: response.data, insurance_id: id}
})
export const addInsurance = createAsyncThunk('appInsurances/addInsurance', async (data) => {
   const response = await axios.post(apiLink('addInsurance'), data)
   return response.data
})
export const removeInsurance = createAsyncThunk('appInsurances/removeInsurance', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeInsurance', value: id}))
   return {response: response.data, insurance_id: id}
})

// ** Payments
export const addPayment = createAsyncThunk('appInsurances/addPayment', async (data) => {
   return await axios.post(apiLink('addPayment'), data)
})
export const removeInsurancePayment = createAsyncThunk('appInsurances/removeInsurancePayment', async (id) => {
   const response = await axios.delete(apiLink({name: 'removePayment', value: id}, {type: 'CAR_INSURANCE'}))
   return response.data
})


export const appInsurancesSlice = createSlice({
  name: 'appInsurances',
  initialState: {
      data: [],
      total: 1,
      params: {},
      all: [],
      currentInsurances: [],
      currentArchive: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getInsurancesList.fulfilled, (state, action) => {
         state.data = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
      .addCase(getCarInsurances.fulfilled, (state, action) => {         
         if (action.payload.active) {            
            state.currentInsurances = action.payload.response
         } else {
            state.currentArchive = action.payload.response
         }

         if (action.payload.clear && state.all.some((insurance) => insurance.car.id === action.payload.car_id)) {
            state.all = [...state.all.filter((insurance) => insurance.car.id !== action.payload.car_id)]            
         } 

         const newRecords = action.payload.response.map(insurance => ({...insurance, active: action.payload.active, ttl: new Date}))

         const uniqueRecords = [...state.all, ...newRecords].reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.id === current.id)) {
               accumulator.push(current)
            }
            return accumulator
         }, [])
         
         state.all = [...uniqueRecords]
      })
      .addCase(getInsurance.fulfilled, (state, action) => {
         const updatedRecords = state.currentInsurances.map((insurance) => {            
            return insurance.id === action.payload.insurance_id ? {...insurance, ...action.payload.response} : {...insurance}            
         })
         state.currentInsurances = [...updatedRecords]

         const updatedAllRecords = state.all.map((insurance) => {            
            return insurance.id === action.payload.insurance_id ? {...insurance, ...action.payload.response, active: true, ttl: new Date} : {...insurance}            
         })
         state.all = [...updatedAllRecords]
      })
      .addCase(addInsurance.fulfilled, (state, action) => {
         state.all = [...state.all, {...action.payload, active: true, ttl: new Date}]
         state.currentInsurances = [...state.currentInsurances, action.payload]
      })      
      .addCase(removeInsurance.fulfilled, (state, action) => {         
         state.currentArchive = [...state.currentArchive, ...state.currentInsurances]
         state.currentInsurances = []
         const updatedRecords = state.all.map((insurance) => {
            if (insurance.id === action.payload.insurance_id) {
               return {...insurance, active: false, ttl: new Date}
            } else {
               return {...insurance}
            }
         })
         state.all = [...updatedRecords]         
      })            
  }
})

export default appInsurancesSlice.reducer
