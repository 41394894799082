// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import { apiLink, checkTtlExpired } from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

export const getCarsList = createAsyncThunk('appCars/getCarsList', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getCarsList', params))
   return {
      params,
      data: response.data.cars,
      totalPages: response.data.total_elements
   }
})

export const getCarBasic = createAsyncThunk('appCars/getCarBasic', async (id) => {
   const response = await axios.get(apiLink({ name: 'getCar', value: id }))
   return response.data
})
export const getCar = createAsyncThunk('appCars/getCar', async ({ id/*, types*/ }, { getState }) => {
   const state = getState()
   const existing = state.cars.all.find(car => car.id === id)
   const clear = existing !== undefined ? checkTtlExpired(existing.ttl) : false

   if (existing === undefined || clear) {
      const car_response = await axios.get(apiLink({ name: 'getCar', value: id }))
      //const media_response = await axios.post(apiLink('getFiles', {typeOwner: 'CAR'}), {ids: [id], types})
      return {...car_response.data/*, media: media_response.data.files*/, clear}
   }

   return existing
})

export const addCar = createAsyncThunk('appCars/addCar', async (data) => {
   const response = await axios.post(apiLink('addCar'), data)
   return response.data
})

export const updateCar = createAsyncThunk('appCars/updateCar', async ({data, types}) => {
   const response = await axios.put(apiLink({name: 'updateCar', value: data.id}), data)
   const media_response = await axios.post(apiLink('getFiles', {typeOwner: 'CAR'}), {ids: [data.id], types})
   return {...response.data, media: media_response.data.files}
})

export const removeCar = createAsyncThunk('appCars/removeCar', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeCar', value: id}))
   return response
})

export const getCarBrands = createAsyncThunk('appCars/getCarBrands', async () => {
   const response = await axios.get(apiLink('getCarBrands'))
   return response.data.brands
})

export const getOffices = createAsyncThunk('appCars/getOffices', async () => {
   //const response = await axios.get('/app/cars/offices')
   return ['Main office']
})

// Media files
export const getMedia = createAsyncThunk('appCars/getMedia', async ({car_id, types}, { getState }) => {
   const state = getState()
   const existing = state.cars.all.find(car => car.id === car_id)   

   if (existing === undefined || existing.media === undefined) {
      const response = await axios.post(apiLink('getFiles', {typeOwner: 'CAR'}), {ids: [car_id], types})   
      return {files: response.data.files, car_id, cache: false}      
   }
    
    const loadedTypes = existing.media.map(media => media.type)
    const missingTypes = types.filter(type => !loadedTypes.includes(type))

    if (missingTypes.length > 0) {
      const response = await axios.post(apiLink('getFiles', { typeOwner: 'CAR' }), { ids: [car_id], types: missingTypes })
      return {files: [...existing.media, ...response.data.files], car_id, cache: false}
    }   

   return {files: existing.media, car_id, cache: true}
})
export const addMedia = createAsyncThunk('appCars/addMedia', async ({car_id, type, typeOwner, file}) => {
   const response = await axios({
        method: "post",
        url: apiLink({name: 'carUploadFile', value: car_id}, {type, typeOwner}),
        data: { file },
        headers: { "Content-Type": "multipart/form-data" }
      })
   return {file: response.data.file, car_id}
})
export const removeMedia = createAsyncThunk('appCars/removeMedia', async ({id, car_id}) => {
   const response = await axios.delete(apiLink({name: 'removeFile', value: id}, {typeOwner: 'CAR'}))
   return {id, status: response.status, car_id}
})

// ** Accidents
export const getAccidents = createAsyncThunk('appCars/getAccidents', async (car_id) => {
   const response = await axios.get(apiLink('getAccidentsList', {car_id}))
   return response.data.roadAccidents
})

// ** History
export const carCustomerHistory = createAsyncThunk('appCars/carCustomerHistory', async (params) => {
   const response = await axios.get(apiLink('carCustomerHistory', params))
   return response.data
})
export const changeCarStatus = createAsyncThunk('appCars/changeCarStatus', async (data) => {   
   const types = data.types
   delete data.types

   const response = await axios.put(apiLink({name: 'changeCarStatus', value: data.id}, {status: data.status, note: data.note}, false))
   const media_response = await axios.post(apiLink('getFiles', {typeOwner: 'CAR'}), {ids: [data.id], types})
   return {...response.data, media: media_response.data.files}
})
export const carByIdCustomerHistory = createAsyncThunk('appCars/carByIdCustomerHistory', async (id) => {
   const response = await axios.get(apiLink({ name: 'carByIdCustomerHistory', value: id}))
   return response.data
})
export const addCarStatusComment = createAsyncThunk('appCars/addCarStatusComment', async (params) => {
   const id = params.id
   delete params.id

   const response = await axios.post(apiLink({ name: 'addCarStatusComment', value: id}, params))
   return response.data
})
export const removeCarStatusComment = createAsyncThunk('appCars/removeCarStatusComment', async (id) => {
   const response = await axios.delete(apiLink({ name: 'removeCarStatusComment', value: id}))
   return response.data
})

// ** export car list
export const getCarsListForExport = createAsyncThunk('appCars/getCarsListForExport', async data => {
   const response = await axios.post(`http://api.mfauto.com.ua/cars/list?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})
export const downloadCarsListForExport = createAsyncThunk('appCars/downloadCarsListForExport', async data => {
   const response = await axios.post(`http://api.mfauto.com.ua/cars/list/download?env=${process.env.REACT_APP_NODE_ENV}`, data, {
      responseType: 'blob'
   })
   return response.data
})

// ** get cars list
export const getCarsListForReport = createAsyncThunk('appCars/getCarsListForReport', async () => {
   const params = {sort_list: 'id', sort_order: 'ASC', rows: 1000}
   const response = await axios.get(apiLink('getCarsShortList', params))
   return response.data.cars
})
export const carReportIncome = createAsyncThunk('appCars/carReportIncome', async data => {
   const response = await axios.post(`http://api.mfauto.com.ua/finance/cars/income?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})

export const appUsersSlice = createSlice({
  name: 'appCars',
  initialState: {
    data: [],
    total: 1,
    params: {},
    media: [],
    all: [],
    brands: [],
    offices: [],
    accidents: [],
    selectedCar: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCarsList.fulfilled, (state, action) => {
         state.data = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
      .addCase(getCar.fulfilled, (state, action) => {
         state.selectedCar = action.payload
         const clear = action.payload.clear
         delete action.payload.clear

         if (clear) {
            if (state.all.some((car) => car.id === action.payload.id)) {
               state.all = state.all.map(car => (car.id === action.payload.id ? {...action.payload, ttl: new Date} : car))
            } else {
               state.all = [...state.all, {...action.payload, ttl: new Date}]
            }
         } else {
            state.all = [...state.all, {...action.payload, ttl: new Date}]
         }
      })
      .addCase(updateCar.fulfilled, (state, action) => {
         state.selectedCar = action.payload
         state.all = state.all.filter(item => item.id !== action.payload.id)
         state.all = [...state.all, {...action.payload, ttl: new Date}]
      })
      .addCase(getMedia.fulfilled, (state, action) => {    
         state.selectedCar = {...state.selectedCar, media: action.payload.files}
         state.all = state.all.map(car => (car.id === action.payload.car_id ? {...car, media: action.payload.files} : car))
      })
      .addCase(addMedia.fulfilled, (state, action) => {
         state.selectedCar = {...state.selectedCar, media: [...state.selectedCar.media, action.payload.file]}
         state.all = state.all.map(car => (car.id === action.payload.car_id ? {...car, media: [...car.media, action.payload.file]} : car))
      })      
      .addCase(removeMedia.fulfilled, (state, action) => {
         if (action.payload.status === 200) {
            const media = state.selectedCar.media.filter(item => item.file_id !== action.payload.id)
            state.selectedCar = {...state.selectedCar, media}
            state.all = state.all.map(car => (car.id === action.payload.car_id ? {...car, media} : car))
         }
      })
      .addCase(getCarBrands.fulfilled, (state, action) => {
         state.brands = action.payload
      })
      .addCase(getOffices.fulfilled, (state, action) => {
         state.offices = action.payload
      })
      .addCase(getAccidents.fulfilled, (state, action) => {
         state.accidents = action.payload
      })
      .addCase(changeCarStatus.fulfilled, (state, action) => {
         state.selectedCar = action.payload
         state.all = state.all.map(car => (car.id === action.payload.id ? {...action.payload, ttl: new Date} : car))
      })
  }
})

export default appUsersSlice.reducer
