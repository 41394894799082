// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** Get actual currency
export const getExchangeRate = createAsyncThunk('appExchange/getExchangeRate', async () => {
   const response = await axios.get(apiLink('getExchangeRate'))   
   return response.data.exchangeRates
})
export const updateExchangeRate = createAsyncThunk('appExchange/updateExchangeRate', async (data) => {
   const currency_id = data.id
   delete data.id
   const response = await axios.put(apiLink({name: 'updateExchangeRate', value: currency_id}), data)
   return response.data
})


export const appExchangeSlice = createSlice({
   name: 'appExchange',
   initialState: {
      exchangeRates: []
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(getExchangeRate.fulfilled, (state, action) => {
            state.exchangeRates = [...action.payload].map(rate => ({ pair: `${rate.currency_from}_${rate.currency_to}`, rate: rate.rate }))
         })
   }
})

export default appExchangeSlice.reducer
