/**
 ** List of params for post URL
*/
export const createParamsListForTable = (params, clear = true) => {
   // ** Remove empty parameters
   if (clear) {
      Object.keys(params).forEach((k) => params[k] === '' && delete params[k])
   }

   const uri = new URLSearchParams(params).toString()

   return `?${uri}`
}
