// ** Reducers Imports
import auth from './authentication'
import cars from '@src/views/cars/store'
import rents from '@src/views/rents/store'
import files from '@src/views/files/store'
import navbar from './navbar'
import layout from './layout'
import credits from '@src/views/credits/store'
import finances from '@src/views/finances/store'
import dashboard from '@src/views/dashboard/store'
import accounts from '@src/views/accounts/store'
import company from '@src/views/company/store'
import deposits from '@src/views/deposits/store'
import currency from '@src/@core/layouts/components/navbar/store'
import customers from '@src/views/customers/store'
import blacklist from '@src/views/black-list/store'
import insurance from '@src/views/insurance/store'
import accidents from '@src/views/accidents/store'
import notifications from '@src/@core/layouts/components/navbar/store/notifications'

const rootReducer = {
  auth,
  cars,
  rents,
  files,
  navbar,
  layout,
  company,
  credits,
  finances,
  accounts,
  deposits,
  currency,
  dashboard,
  customers,
  blacklist,
  insurance,
  accidents,
  notifications
}

export default rootReducer
