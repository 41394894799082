// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** UseJWT import to get config
import useJwt from '@src/utility/useJwt'

const config = useJwt.jwtConfig

// ** TEMPORARY CHANGE ABILITIES
//import { abilityList } from '../views/pages/authentication/ability'

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const getUserProfileDetails = createAsyncThunk('authentication/getUserDetails', async () => {
   const response = await axios.get(apiLink('getUserProfileDetails'))
   return response
})

export const authSlice = createSlice({
   name: 'authentication',
   initialState: {
      userData: initialUser()
   },
   reducers: {
      handleLogin: (state, action) => {
         state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
         state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
         localStorage.setItem(config.storageTokenKeyName, action.payload.token)
         localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
      },
      handleLogout: state => {
         state.userData = {}
         state[config.storageTokenKeyName] = null
         state[config.storageRefreshTokenKeyName] = null
         // ** Remove user, accessToken & refreshToken from localStorage
         localStorage.removeItem('userData')
         localStorage.removeItem(config.storageTokenKeyName)
         localStorage.removeItem(config.storageRefreshTokenKeyName)
      }
   },
   extraReducers: builder => {
      builder
      .addCase(getUserProfileDetails.fulfilled, (state, action) => {

         const role_name = action.payload.data.role.name,
               ability   = [{subject: "PROFILE_VIEW", action: "read"}, ...action.payload.data.role.permissions.map((item) => ({subject: item, action: 'read'}))]

         state.userData = action.payload.data
         state.userData.role = role_name
         state.userData.ability = ability

         localStorage.setItem('userData', JSON.stringify(state.userData))
      })
   }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
